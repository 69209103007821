import React, { useState } from "react";
import styles from "./style.module.css";
import apis from "../../assets/apis";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const PhoneNumberInput = ({
	setOtpSent,
	phone,
	setPhone,
	setOtpApiBody,
	setNotification,
}) => {
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const handlePhoneChange = (e) => {
		const value = e.target.value;
		if (value.length <= 10 && /^\d*$/.test(value)) {
			setPhone(value);
		}
	};

	const sendOtp = async () => {
		if (phone.length < 10) return;
		setIsButtonDisabled(true); // Disable button on click
		try {
			const response = await fetch(apis.LOGIN_WITH_PHONE_NUMBER, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ phone: `91${phone}` }),
			});

			console.log(response.ok);
			if (response.ok) {
				setNotification({
					show: true,
					message: "OTP sent successfully",
					severity: "success",
				});
				const body = await response.json();
				setOtpApiBody(body);
				setOtpSent(true); // Notify parent component that OTP was sent
			} else {
				const data = await response.json();
				setNotification({
					show: true,
					message: data.message || "Error sending OTP",
					severity: "warning",
				});
			}
		} catch (error) {
			setNotification({
				show: true,
				message: "Network error",
				severity: "warning",
			});
		} finally {
			setIsButtonDisabled(false); // Enable button again after sending OTP
		}
	};

	return (
		<div className={styles.loginForm}>
			<div className={styles.heading1}>Enter Your Mobile Number</div>
			<span
				style={{
					fontSize: "12px",
					color: "grey",
					display: "flex",
					alignItems: "center",
				}}
			>
				{/* WhatsApp green */}
				We will send you a verification code on&nbsp;
				<span style={{ color: "#25D366" }}> WhatsApp&nbsp;</span>
				<WhatsAppIcon
					style={{
						fontSize: "14px",
						color: "#25D366", // WhatsApp green
						marginRight: "5px",
					}}
				/>
			</span>
			<div className={styles.inputContainer}>
				<span>+91</span>
				<input
					type="number"
					value={phone}
					onChange={handlePhoneChange}
					placeholder="Phone No."
					maxLength={10}
					required
					className={styles.input_number}
				/>
			</div>
			<button
				type="submit"
				className={styles.button_submit}
				onClick={sendOtp}
				disabled={isButtonDisabled} // Disable the button while OTP is being sent
				style={{
					backgroundColor: isButtonDisabled ? "#d3d3d3" : "#388e3c", // Change color based on disabled state
					cursor: isButtonDisabled ? "not-allowed" : "pointer", // Change cursor style for disabled button
				}}
			>
				{isButtonDisabled ? "Sending OTP..." : "Send OTP"}
			</button>
		</div>
	);
};

export default PhoneNumberInput;
